import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router'
/* Layout */
import Layout from '@/layout/index.vue'

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes: AppRouteRecordRaw[] = [
  {
    name: 'Redirect',
    path: '/redirect',
    component: Layout,
    children: [
      {
        name: 'Redirect',
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    name: 'Login',
    path: '/login',
    component: () => import('@/views/login.vue'),
    meta: {
      hidden: true
    }
  },
  {
    name: 'Register',
    path: '/register',
    component: () => import('@/views/register.vue'),
    meta: {
      hidden: true
    }
  },
  {
    name: 'PathMatch',
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error/404.vue'),
    meta: {
      hidden: true
    }
  },
  {
    name: '401',
    path: '/401',
    component: () => import('@/views/error/401.vue'),
    meta: {
      hidden: true
    }
  },
  {
    name: 'Layout',
    path: '',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        component: () => import('@/views/index.vue'),
        name: 'Index',
        meta: { title: '首页', icon: 'dashboard', affix: true, noCache: false }
      }
    ],
    meta: {}
  },
  {
    name: 'UserProfile',
    path: '/user',
    component: Layout,
    redirect: 'noredirect',
    children: [
      {
        path: 'profile',
        component: () => import('@/views/system/user/profile/index.vue'),
        name: 'Profile',
        meta: { title: '个人中心', icon: 'user' }
      }
    ],
    meta: {
      hidden: true
    }
  },
  {
    name: 'CardTable',
    path: '/card-table',
    component: Layout,
    redirect: 'noredirect',
    children: [
      {
        path: 'list',
        component: () => import('@/views/cardTable/list.vue'),
        name: 'CardTableList',
        meta: { title: '牌局管理', icon: 'redis' }
      },
      {
        path: 'add',
        component: () => import('@/views/cardTable/option.vue'),
        name: 'CardTableAdd',
        meta: { title: '新增牌局', icon: 'user', hidden: true }
      },
      {
        path: ':type(clone)/:id(\\d+)',
        component: () => import('@/views/cardTable/option.vue'),
        name: 'CardTableClone',
        meta: { title: '克隆牌局', icon: 'user', hidden: true }
      },
      {
        path: ':id(\\d+)',
        component: () => import('@/views/cardTable/option.vue'),
        name: 'CardTableEdit',
        meta: { title: '编辑牌局', icon: 'user', hidden: true }
      },
      {
        path: 'detail/:id(\\d+)',
        component: () => import('@/views/cardTable/option.vue'),
        name: 'CardTableDetail',
        meta: { title: '牌局详情', icon: 'user', hidden: true }
      }
      // {
      //   path: 'test',
      //   component: () => import('@/views/cardTable/components/FlowChart/index.vue'),
      //   // component: () => import('@/components/Flow_old/LF.vue'),
      //   name: 'FlowTest',
      //   meta: { title: 'FlowTest', icon: 'user' }
      // }
    ],
    meta: {
      title: '牌局管理',
      icon: 'redis'
    }
  },
  {
    name: 'CourseManage',
    path: '/course',
    component: Layout,
    redirect: 'noredirect',
    children: [
      // ********  条目相关路由 *********
      {
        path: 'entry/list',
        component: () => import('@/views/entry/list.vue'),
        name: 'EntryList',
        meta: { title: '小程序课程列表', icon: 'icon' }
      },

      // ********  课程相关路由 *********
      {
        path: 'list',
        component: () => import('@/views/course/list.vue'),
        name: 'CourseList',
        meta: { title: '课程配置', icon: 'exit-fullscreen' }
      },
      {
        path: 'add/practice',
        component: () => import('@/views/course/practice.vue'),
        name: 'CourseAddPractice',
        meta: { title: '新增训练课程', hidden: true }
      },
      {
        path: 'add/question',
        component: () => import('@/views/course/question.vue'),
        name: 'CourseAddQuestion',
        meta: { title: '新增问答课程', hidden: true }
      },
      {
        path: 'add/im',
        component: () => import('@/views/course/im.vue'),
        name: 'CourseAddIm',
        meta: { title: '新增对话课程', hidden: true }
      },
      {
        path: 'add/vedio',
        component: () => import('@/views/course/vedio.vue'),
        name: 'CourseAddVedio',
        meta: { title: '新增视频课程', hidden: true }
      },
      {
        path: ':type(update)/:id(\\d+)',
        component: () => import('@/views/course/components/Option.vue'),
        name: 'CourseAdd',
        meta: { title: '编辑课程', hidden: true }
      },
      {
        path: ':type(clone)/:id(\\d+)',
        component: () => import('@/views/course/components/Option.vue'),
        name: 'CourseClone',
        meta: { title: '克隆课程', hidden: true }
      },
      {
        path: ':type(check)/:id(\\d+)',
        component: () => import('@/views/course/components/Option.vue'),
        name: 'CourseDetail',
        meta: { title: '课程详情', hidden: true }
      },

      // ********  课程包相关路由 *********
      {
        path: 'pkg/list',
        component: () => import('@/views/pkg/list.vue'),
        name: 'PkgList',
        meta: { title: '课程包管理', icon: 'dict' }
      },
      {
        path: 'pkg/add',
        component: () => import('@/views/pkg/option.vue'),
        name: 'PkgAdd',
        meta: { title: '新增课程包', hidden: true }
      },
      {
        path: 'pkg/:type(clone)/:id(\\d+)',
        component: () => import('@/views/pkg/option.vue'),
        name: 'PkgClone',
        meta: { title: '克隆课程包', hidden: true }
      },
      {
        path: 'pkg/:id(\\d+)',
        component: () => import('@/views/pkg/option.vue'),
        name: 'PkgEdit',
        meta: { title: '编辑课程包', hidden: true }
      },
      {
        path: 'pkg/detail/:id(\\d+)',
        component: () => import('@/views/pkg/option.vue'),
        name: 'PkgDetail',
        meta: { title: '课程包详情', hidden: true }
      },

      // ********  问答相关路由 *********
      {
        path: 'question/list',
        component: () => import('@/views/question/list.vue'),
        name: 'QuestionList',
        meta: { title: '问答管理', icon: 'lock' }
      },
      {
        path: 'question/add',
        component: () => import('@/views/question/option.vue'),
        name: 'QuestionAdd',
        meta: { title: '新增问答', hidden: true }
      },
      {
        path: 'question/:type(clone)/:id(\\d+)',
        component: () => import('@/views/question/option.vue'),
        name: 'QuestionClone',
        meta: { title: '克隆问答', hidden: true }
      },
      {
        path: 'question/:id(\\d+)',
        component: () => import('@/views/question/option.vue'),
        name: 'QuestionEdit',
        meta: { title: '编辑问答', hidden: true }
      },
      {
        path: 'question/detail/:id(\\d+)',
        component: () => import('@/views/question/option.vue'),
        name: 'QuestionDetail',
        meta: { title: '问答详情', hidden: true }
      },

      // ********  分类相关路由 *********
      {
        path: 'category/list',
        component: () => import('@/views/category/list.vue'),
        name: 'CategoryList',
        meta: { title: '分类管理', icon: 'tree-table' }
      }
    ],
    meta: {
      title: '课程管理',
      icon: 'example'
    }
  },
  {
    name: 'VipManage',
    path: '/vip',
    component: Layout,
    redirect: 'noredirect',
    children: [
      {
        path: 'user/list',
        component: () => import('@/views/vip/userList.vue'),
        name: 'VipUserList',
        meta: { title: '用户', icon: 'user' }
      },
      // {
      //   path: 'recharge/list',
      //   component: () => import('@/views/vip/rechargeList.vue'),
      //   name: 'RechargeList',
      //   meta: { title: '充值记录-旧版', icon: 'shopping' }
      // },
      {
        path: 'pay/list',
        component: () => import('@/views/vip/payList.vue'),
        name: 'PayList',
        meta: { title: '线下充值', icon: 'shopping' }
      },
      {
        path: 'benefit/list',
        component: () => import('@/views/vip/benefitList.vue'),
        name: 'BenefitList',
        meta: { title: '权益记录', icon: 'money' }
      },
      {
        path: 'list',
        component: () => import('@/views/vip/list.vue'),
        name: 'VipList',
        meta: { title: '会员等级', icon: 'strike' }
      },
      {
        path: 'add',
        component: () => import('@/views/vip/option.vue'),
        name: 'VipOption',
        meta: { title: '新增会员', icon: 'strike', hidden: true }
      },
      {
        path: ':type(clone)/:id(\\d+)',
        component: () => import('@/views/vip/option.vue'),
        name: 'VipOption',
        meta: { title: '克隆会员', icon: 'strike', hidden: true }
      },
      {
        path: ':id(\\d+)',
        component: () => import('@/views/vip/option.vue'),
        name: 'VipOption',
        meta: { title: '编辑会员', icon: 'strike', hidden: true }
      },
      {
        path: 'loginRecord',
        component: () => import('@/views/vip/loginRecord.vue'),
        name: 'LoginRecord',
        meta: { title: '登录记录', icon: 'date' }
      }
    ],
    meta: {
      title: '会员',
      icon: 'user'
    }
  },
  {
    name: 'AbilityManage',
    path: '/ability',
    component: Layout,
    redirect: 'noredirect',
    children: [
      {
        path: 'list',
        component: () => import('@/views/ability/list.vue'),
        name: 'AbilityList',
        meta: { title: '能力管理', icon: 'tree' }
      }
    ],
    meta: {
      title: '能力管理',
      icon: 'tree'
    }
  },
  {
    name: 'KnowledgeManage',
    path: '/knowledge',
    component: Layout,
    redirect: 'noredirect',
    children: [
      {
        path: 'list',
        component: () => import('@/views/knowledge/list.vue'),
        name: 'KnowledgeList',
        meta: { title: '资讯管理', icon: 'excel' }
      },
      {
        path: 'add',
        component: () => import('@/views/knowledge/option.vue'),
        name: 'KnowledgeAdd',
        meta: { title: '新增资讯', icon: 'user', hidden: true }
      },
      {
        path: ':id(\\d+)',
        component: () => import('@/views/knowledge/option.vue'),
        name: 'KnowledgeEdit',
        meta: { title: '编辑资讯', icon: 'user', hidden: true }
      }
    ],
    meta: {
      title: '资讯管理',
      icon: 'excel'
    }
  },
  {
    name: 'RobotManage',
    path: '/robot',
    component: Layout,
    redirect: 'noredirect',
    children: [
      {
        path: 'list',
        component: () => import('@/views/robot/list.vue'),
        name: 'RobotList',
        meta: { title: '机器人', icon: 'robots' }
      },
      {
        path: 'add',
        component: () => import('@/views/robot/option.vue'),
        name: 'RobotAdd',
        meta: { title: '新增机器人', icon: 'user', hidden: true }
      },
      {
        path: ':type(clone)/:id(\\d+)',
        component: () => import('@/views/robot/option.vue'),
        name: 'RobotClone',
        meta: { title: '克隆机器人', icon: 'user', hidden: true }
      },
      {
        path: ':id(\\d+)',
        component: () => import('@/views/robot/option.vue'),
        name: 'RobotEdit',
        meta: { title: '编辑机器人', icon: 'user', hidden: true }
      },
      {
        path: 'detail/:id(\\d+)',
        component: () => import('@/views/robot/option.vue'),
        name: 'RobotDetail',
        meta: { title: '机器人详情', icon: 'user', hidden: true }
      }
    ],
    meta: {
      title: '机器人',
      icon: 'robots'
    }
  }
]

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes: AppRouteRecordRaw[] = [
  {
    name: 'UserAuth',
    path: '/system/user-auth',
    component: Layout,
    permissions: ['system:user:edit'],
    children: [
      {
        path: 'role/:userId(\\d+)',
        component: () => import('@/views/system/user/authRole.vue'),
        name: 'AuthRole',
        meta: { title: '分配角色', activeMenu: '/system/user' }
      }
    ],
    meta: {
      hidden: true
    }
  },
  {
    name: 'RoleAuth',
    path: '/system/role-auth',
    component: Layout,
    permissions: ['system:role:edit'],
    children: [
      {
        path: 'user/:roleId(\\d+)',
        component: () => import('@/views/system/role/authUser.vue'),
        name: 'AuthUser',
        meta: { title: '分配用户', activeMenu: '/system/role' }
      }
    ],
    meta: {
      hidden: true
    }
  },
  {
    name: 'DictData',
    path: '/system/dict-data',
    component: Layout,
    permissions: ['system:dict:list'],
    children: [
      {
        path: 'index/:dictId(\\d+)',
        component: () => import('@/views/system/dict/data.vue'),
        name: 'Data',
        meta: { title: '字典数据', activeMenu: '/system/dict' }
      }
    ],
    meta: {
      hidden: true
    }
  },
  {
    name: 'JobLog',
    path: '/monitor/job-log',
    component: Layout,
    permissions: ['monitor:job:list'],
    children: [
      {
        path: 'index/:jobId(\\d+)',
        component: () => import('@/views/monitor/job/log.vue'),
        name: 'JobLog',
        meta: { title: '调度日志', activeMenu: '/monitor/job' }
      }
    ],
    meta: {
      hidden: true
    }
  },
  {
    name: 'GenEdit',
    path: '/tool/gen-edit',
    component: Layout,
    permissions: ['tool:gen:edit'],
    children: [
      {
        path: 'index/:tableId(\\d+)',
        component: () => import('@/views/tool/gen/editTable.vue'),
        name: 'GenEdit',
        meta: { title: '修改生成配置', activeMenu: '/tool/gen' }
      }
    ],
    meta: {
      hidden: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes as RouteRecordRaw[],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
